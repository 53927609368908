<template>
  <v-container fluid>
    <h3>Session In Progress</h3>
    <div
      class="mt-4 red--text"
      v-if="latestChargingSession && latestChargingSession.forceStopped"
    >
      Stopped by system due to lack of points
    </div>
    <v-card class="mt-4" v-if="item && connector">
      <v-card-text>
        <div>
          <v-row>
            <v-col
              cols="7"
              style="
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0px;
                text-align: left;
                color: rgba(51, 51, 51, 1);
              "
            >
              {{ item.uniqueId }}#{{ connector.connectorId }}
            </v-col>
            <v-col cols="5" class="text-right">
              <span
                style="
                  text-v-rowansform: initial;
                  font-family: Roboto;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 14px;
                  letter-spacing: 0px;
                  text-align: left;
                "
                @click="
                  $router.push(
                    `/app/charging_sessions_m?chargingStationId=${item._id}`
                  )
                "
              >
                Charging history
                <v-icon size="16px">mdi-chevron-right</v-icon>
              </span>
            </v-col>
          </v-row>
        </div>
        <template v-if="latestChargingSession">
          <v-row>
            <v-col class="label" cols="4">Status</v-col>
            <v-col class="label-value">
              <v-chip
                label
                small
                :color="
                  (['Available'].includes(connector.status) && 'success') ||
                  (['Unavailable', 'Offline'].includes(connector.status) &&
                    'error') ||
                  'info'
                "
                >{{ connector.status }}</v-chip
              >
            </v-col>
          </v-row>
          <template
            v-if="
              (latestChargingSession.details &&
                (!latestChargingSession.details.stop ||
                  !latestChargingSession.stopTime ||
                  latestChargingSession.stopTime <
                    latestChargingSession.startTime)) ||
              latestChargingSessionUpdated ||
              chargingSessionId
            "
          >
            <template v-if="latestChargingSession.details">
              <template
                v-if="
                  chargingSessionId ||
                  !['Available', 'Preparing'].includes(connector.status)
                "
              >
                <v-row>
                  <v-col class="label" cols="4">Charging Power</v-col>
                  <v-col class="label-value">
                    {{
                      (meterValue("Power.Active.Import") / 1000) | number3(2, 0)
                    }}
                    kW
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" cols="4">SoC</v-col>
                  <v-col class="label-value">
                    {{
                      (latestChargingSession.details.stop &&
                        latestChargingSession.stopTime >
                          latestChargingSession.startTime &&
                        latestChargingSession.details.stateOfCharge) ||
                      latestChargingSession.details.currentStateOfCharge ||
                      latestChargingSession.details.stateOfCharge ||
                      0
                    }}
                    %
                  </v-col>
                </v-row>
              </template>
              <v-row>
                <v-col class="label" cols="4">Energy Delivered</v-col>
                <v-col class="label-value">
                  {{
                    (((latestChargingSession.details.stop &&
                      latestChargingSession.stopTime >
                        latestChargingSession.startTime &&
                      latestChargingSession.details.stop.totalConsumptionWh) ||
                      latestChargingSession.details.currentTotalConsumptionWh ||
                      0) /
                      1000)
                      | number3(2, 0)
                  }}
                  kWh
                </v-col>
              </v-row>
            </template>
            <template
              v-if="
                latestChargingSession.details.stop &&
                latestChargingSession.stopTime > latestChargingSession.startTime
              "
            >
              <v-row>
                <v-col class="label" cols="4">Start Time</v-col>
                <v-col class="label-value">{{
                  latestChargingSession.startTime | dateTime
                }}</v-col>
              </v-row>
              <v-row>
                <v-col class="label" cols="4">Stop Time</v-col>
                <v-col class="label-value">{{
                  latestChargingSession.stopTime | dateTime
                }}</v-col>
              </v-row>
              <v-row>
                <v-col class="label" cols="4">Duration</v-col>
                <v-col class="label-value">{{
                  (latestChargingSession.details.stop.totalDurationSecs * 1000)
                    | duration
                }}</v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row>
                <v-col class="label" cols="4">Start Time</v-col>
                <v-col class="label-value">{{
                  latestChargingSession.startTime | dateTime
                }}</v-col>
              </v-row>
              <v-row>
                <v-col class="label" cols="4">Duration</v-col>
                <v-col class="label-value">{{
                  currentDuration | duration
                }}</v-col>
              </v-row>
            </template>
          </template>
          <div
            class="mt-8"
            v-if="
              !chargingSessionId ||
              !latestChargingSession ||
              !latestChargingSession.stopTime
            "
          >
            <x-btn
              :x-options="startChargingBtnOptions"
              :x-context="self"
              :key="`start-${connector.status}`"
              v-if="['Available', 'Preparing'].includes(connector.status)"
            ></x-btn>
            <x-btn
              :x-options="stopChargingBtnOptions"
              :x-context="self"
              :key="`stop-${connector.status}`"
              v-if="['Charging'].includes(connector.status)"
            ></x-btn>
          </div>
        </template>
        <template v-else>
          <div class="text-center">No charging session found</div>
        </template>
      </v-card-text>
    </v-card>
    <loading v-else-if="initializeLoading"></loading>
    <div class="red--text text-center" v-else-if="initializeError">
      {{ initializeError }}
    </div>
    <div class="text-center" v-else>
      Charging Station or Connector not found
    </div>
  </v-container>
</template>

<script>
import coreApiClient from "@/services/apis/coreApiClient";
import Vue from "vue";
import centrifugeClientFactory from "@/services/centrifugeClientFactory";
import merge from "@common/modules/vuetifyx/utils/merge";
import Loading from "@/components/Loading.vue";
import userManager from "@/services/userManager";
import appService from "@/services/appService";
export default Vue.extend({
  components: { Loading },
  data() {
    return {
      self: this,
      refreshBtnOptions: {
        attrs: {
          text: true,
        },
        content: {
          icon: "mdi-refresh",
        },
        on: {
          async xClick({ self }) {
            self.context().initialize();
          },
        },
        target: {
          tooltip: {
            content: {
              text: "Refresh",
            },
          },
        },
      },
      startChargingBtnOptions: {
        attrs: {
          block: true,
          color: "primary",
          dark: true,
          small: true,
        },
        content: {
          text: "Start Charging",
        },
        target: {
          tooltip: {
            content: {
              text: "Start Charging",
            },
          },
          confirm: {
            content: {
              content: {
                html: "Are you sure you want to start charging?",
              },
            },
            on: {
              async xYes({ self }) {
                const yesBtn = self.yesBtnContainer.value;
                const btn = self.context();
                const { item, connector } = btn.context();
                yesBtn.loading.value = true;
                const result = await coreApiClient.call(
                  "chargingStations",
                  "startCharging",
                  {
                    id: item.id,
                    connectorId: connector.connectorId,
                  }
                );
                yesBtn.loading.value = false;
                if (result) {
                  self.hide();
                }
              },
            },
          },
        },
      },
      stopChargingBtnOptions: {
        attrs: {
          block: true,
          color: "primary",
          dark: true,
          small: true,
        },
        content: {
          text: "Stop Charging",
        },
        target: {
          tooltip: {
            content: {
              text: "Stop Charging",
            },
          },
          confirm: {
            content: {
              content: {
                html: "Are you sure you want to stop charging?",
              },
            },
            on: {
              async xYes({ self }) {
                const yesBtn = self.yesBtnContainer.value;
                const btn = self.context();
                const { item, connector } = btn.context();
                yesBtn.loading.value = true;
                const result = await coreApiClient.call(
                  "chargingStations",
                  "stopCharging",
                  {
                    id: item.id,
                    connectorId: connector.connectorId,
                  }
                );
                yesBtn.loading.value = false;
                if (result) {
                  self.hide();
                }
              },
            },
          },
        },
      },
      initializeLoading: false,
      initializeError: null,
      item: null,
      connector: null,
      latestChargingSession: null,
      latestChargingSessionUpdated: false,
      currentDuration: null,
      chargingSessionId: null,
    };
  },
  methods: {
    async initialize() {
      const id = this.$route.params.id;
      const connectorId = this.$route.params.connectorId;
      const chargingSessionId = this.$route.query.chargingSessionId;
      this.chargingSessionId = chargingSessionId;
      const self = this;

      setInterval(() => {
        this.currentDuration = this.getCurrentDuration();
      }, 1000);

      this.initializeLoading = true;
      try {
        const item = await coreApiClient.call("chargingStations", "findById", {
          id,
        });
        if (item) {
          this.item = item;
        }
        if (this.item) {
          const connector =
            this.item.details &&
            this.item.details.connectors &&
            this.item.details.connectors.find(
              (c) => +c.connectorId === +connectorId
            );
          if (connector) {
            this.connector = connector;
          }
        }
        if (this.item) {
          const activeTenant = appService.getActiveTenant();
          const socketId = userManager.getUserInfo()._id;
          let channelName = "";

          if (activeTenant) {
              channelName = `private-chargingStations-tenantId=${activeTenant._id}`;
          } else if (userManager.checkRole(["cpoAdmin", "cpoMonitor"], true)) {
              channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id
                  }`;
          } else if (userManager.checkRole(["retailer"], true)) {
              channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id
                  }-retailerAdminUserId=${userManager.getUserInfo()._id}`;
          } else if (userManager.checkRole(["user"], true)) {
              channelName = `private-chargingStations-tenantId=${userManager.getUserInfo().tenant._id
                  }-connectorItemsActiveUserId=${userManager.getUserInfo()._id}`;
          } else {
              channelName = `private-chargingStations`;
          }

          
          const centrifuge = centrifugeClientFactory(null, async () => {
            const {
              auth
            } = await coreApiClient.callRaw("chargingStations", "subscribe", {
              socketId,
              channelName,
              connectorId,
              id: self.item._id,
            });
            return auth;
          });
          this.centrifuge = centrifuge;
          const sub = centrifuge
            .newSubscription(
              `private-chargingStations-tenantId=${this.item.tenantId}-uniqueId=${this.item.uniqueId}`
            )
          sub.on("publication", (ctx) => {
            const data = ctx.data;
            self.$set(self, "item", merge.clone(self.item, data));
            const connector =
              self.item.details &&
              self.item.details.connectors &&
              self.item.details.connectors.find(
                (c) => +c.connectorId === +connectorId
              );
            if (connector) {
              self.$set(self, "connector", connector);
              if (["Available", "Preparing"].includes(connector.status)) {
                if (
                  self.latestChargingSession &&
                  self.latestChargingSession.details
                ) {
                  self.$set(
                    self.latestChargingSession.details,
                    "rawMeterValues",
                    null
                  );
                }
              }
            }
          });
          sub.subscribe();
          const subSession = centrifuge
            .newSubscription(
              `private-chargingSessions-tenantId=${this.item.tenantId}-chargingStationId=${this.item._id}-connectorId=${connectorId}`
            )
          subSession.on("publication", (ctx) => {
              const data = (ctx.data);
              self.$set(
                self,
                "latestChargingSession",
                merge.clone(self.latestChargingSession || {}, data)
              );
              self.$set(self, "latestChargingSessionUpdated", true);
            });
          subSession.subscribe();
          centrifuge.connect();
        }
        if (this.connector) {
          let filters = [
            {
              key: "chargingStationId",
              operator: "equal_to",
              value: id,
            },
            {
              key: "connectorId",
              operator: "equal_to",
              value: +connectorId,
            },
          ];
          if (chargingSessionId) {
            filters = [
              {
                key: "_id",
                operator: "equal_to_id",
                value: chargingSessionId,
              },
            ];
          }
          this.latestChargingSession = await coreApiClient.call(
            "chargingSessions",
            "findOne",
            {
              payload: JSON.stringify({
                orderBy: "startTime",
                orderType: "desc",
                filters,
              }),
            }
          );
        }
      } catch (e) {
        this.initializeError = e;
      } finally {
        this.initializeLoading = false;
      }
    },
    meterValue(key) {
      if (!this.latestChargingSession || !this.latestChargingSession.details) {
        return 0;
      }
      if (
        this.latestChargingSession.details.rawMeterValues &&
        this.latestChargingSession.details.rawMeterValues.meterValues &&
        this.latestChargingSession.details.rawMeterValues.meterValues
          .meterValue &&
        this.latestChargingSession.details.rawMeterValues.meterValues
          .meterValue[0] &&
        this.latestChargingSession.details.rawMeterValues.meterValues
          .meterValue[0].sampledValue
      ) {
        const arr =
          this.latestChargingSession.details.rawMeterValues.meterValues
            .meterValue[0].sampledValue;
        const item = arr.find((it) => it.measurand === key);
        if (item) {
          return parseFloat(item.value);
        }
      }
      return 0;
    },
    getCurrentDuration() {
      if (!this.latestChargingSession) {
        return "";
      }
      return new Date().getTime() - this.latestChargingSession.startTime;
    },
  },
  async created() {
    await this.initialize();
  },
  destroyed() {
    if (this.centrifuge) {
      this.centrifuge.disconnect();
    }
  },
});
</script>

<style scoped>
.label {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(153, 153, 153, 1);
}

.label-value {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(51, 51, 51, 1);
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('h3',[_vm._v("Session In Progress")]),(_vm.latestChargingSession && _vm.latestChargingSession.forceStopped)?_c('div',{staticClass:"mt-4 red--text"},[_vm._v(" Stopped by system due to lack of points ")]):_vm._e(),(_vm.item && _vm.connector)?_c('v-card',{staticClass:"mt-4"},[_c('v-card-text',[_c('div',[_c('v-row',[_c('v-col',{staticStyle:{"font-family":"Roboto","font-size":"14px","font-weight":"500","line-height":"16px","letter-spacing":"0px","text-align":"left","color":"rgba(51, 51, 51, 1)"},attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(_vm.item.uniqueId)+"#"+_vm._s(_vm.connector.connectorId)+" ")]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"5"}},[_c('span',{staticStyle:{"text-v-rowansform":"initial","font-family":"Roboto","font-size":"12px","font-weight":"500","line-height":"14px","letter-spacing":"0px","text-align":"left"},on:{"click":function($event){return _vm.$router.push(
                  ("/app/charging_sessions_m?chargingStationId=" + (_vm.item._id))
                )}}},[_vm._v(" Charging history "),_c('v-icon',{attrs:{"size":"16px"}},[_vm._v("mdi-chevron-right")])],1)])],1)],1),(_vm.latestChargingSession)?[_c('v-row',[_c('v-col',{staticClass:"label",attrs:{"cols":"4"}},[_vm._v("Status")]),_c('v-col',{staticClass:"label-value"},[_c('v-chip',{attrs:{"label":"","small":"","color":(['Available'].includes(_vm.connector.status) && 'success') ||
                (['Unavailable', 'Offline'].includes(_vm.connector.status) &&
                  'error') ||
                'info'}},[_vm._v(_vm._s(_vm.connector.status))])],1)],1),(
            (_vm.latestChargingSession.details &&
              (!_vm.latestChargingSession.details.stop ||
                !_vm.latestChargingSession.stopTime ||
                _vm.latestChargingSession.stopTime <
                  _vm.latestChargingSession.startTime)) ||
            _vm.latestChargingSessionUpdated ||
            _vm.chargingSessionId
          )?[(_vm.latestChargingSession.details)?[(
                _vm.chargingSessionId ||
                !['Available', 'Preparing'].includes(_vm.connector.status)
              )?[_c('v-row',[_c('v-col',{staticClass:"label",attrs:{"cols":"4"}},[_vm._v("Charging Power")]),_c('v-col',{staticClass:"label-value"},[_vm._v(" "+_vm._s(_vm._f("number3")((_vm.meterValue("Power.Active.Import") / 1000),2, 0))+" kW ")])],1),_c('v-row',[_c('v-col',{staticClass:"label",attrs:{"cols":"4"}},[_vm._v("SoC")]),_c('v-col',{staticClass:"label-value"},[_vm._v(" "+_vm._s((_vm.latestChargingSession.details.stop && _vm.latestChargingSession.stopTime > _vm.latestChargingSession.startTime && _vm.latestChargingSession.details.stateOfCharge) || _vm.latestChargingSession.details.currentStateOfCharge || _vm.latestChargingSession.details.stateOfCharge || 0)+" % ")])],1)]:_vm._e(),_c('v-row',[_c('v-col',{staticClass:"label",attrs:{"cols":"4"}},[_vm._v("Energy Delivered")]),_c('v-col',{staticClass:"label-value"},[_vm._v(" "+_vm._s(_vm._f("number3")((((_vm.latestChargingSession.details.stop && _vm.latestChargingSession.stopTime > _vm.latestChargingSession.startTime && _vm.latestChargingSession.details.stop.totalConsumptionWh) || _vm.latestChargingSession.details.currentTotalConsumptionWh || 0) / 1000),2, 0))+" kWh ")])],1)]:_vm._e(),(
              _vm.latestChargingSession.details.stop &&
              _vm.latestChargingSession.stopTime > _vm.latestChargingSession.startTime
            )?[_c('v-row',[_c('v-col',{staticClass:"label",attrs:{"cols":"4"}},[_vm._v("Start Time")]),_c('v-col',{staticClass:"label-value"},[_vm._v(_vm._s(_vm._f("dateTime")(_vm.latestChargingSession.startTime)))])],1),_c('v-row',[_c('v-col',{staticClass:"label",attrs:{"cols":"4"}},[_vm._v("Stop Time")]),_c('v-col',{staticClass:"label-value"},[_vm._v(_vm._s(_vm._f("dateTime")(_vm.latestChargingSession.stopTime)))])],1),_c('v-row',[_c('v-col',{staticClass:"label",attrs:{"cols":"4"}},[_vm._v("Duration")]),_c('v-col',{staticClass:"label-value"},[_vm._v(_vm._s(_vm._f("duration")((_vm.latestChargingSession.details.stop.totalDurationSecs * 1000))))])],1)]:[_c('v-row',[_c('v-col',{staticClass:"label",attrs:{"cols":"4"}},[_vm._v("Start Time")]),_c('v-col',{staticClass:"label-value"},[_vm._v(_vm._s(_vm._f("dateTime")(_vm.latestChargingSession.startTime)))])],1),_c('v-row',[_c('v-col',{staticClass:"label",attrs:{"cols":"4"}},[_vm._v("Duration")]),_c('v-col',{staticClass:"label-value"},[_vm._v(_vm._s(_vm._f("duration")(_vm.currentDuration)))])],1)]]:_vm._e(),(
            !_vm.chargingSessionId ||
            !_vm.latestChargingSession ||
            !_vm.latestChargingSession.stopTime
          )?_c('div',{staticClass:"mt-8"},[(['Available', 'Preparing'].includes(_vm.connector.status))?_c('x-btn',{key:("start-" + (_vm.connector.status)),attrs:{"x-options":_vm.startChargingBtnOptions,"x-context":_vm.self}}):_vm._e(),(['Charging'].includes(_vm.connector.status))?_c('x-btn',{key:("stop-" + (_vm.connector.status)),attrs:{"x-options":_vm.stopChargingBtnOptions,"x-context":_vm.self}}):_vm._e()],1):_vm._e()]:[_c('div',{staticClass:"text-center"},[_vm._v("No charging session found")])]],2)],1):(_vm.initializeLoading)?_c('loading'):(_vm.initializeError)?_c('div',{staticClass:"red--text text-center"},[_vm._v(" "+_vm._s(_vm.initializeError)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" Charging Station or Connector not found ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }